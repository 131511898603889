<template>
  <div>
    <section>
      <b-modal :active="isComponentModalActive" @close="closeModal" has-modal-card>
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Escaneie o QRCode com o whatsapp</p>
          </header>
          <section class="modal-card-body">
            <b-field :label="qrCode.phone">
              <b-image
                :src="qrCode.qrCodeImage"
                alt="QRCode"
                ratio="300by300"
              ></b-image>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="closeModal">Fechar</button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ModalQrCode',
  props: {
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    qrCode: {
      type: Object,
      required: true
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    }
  }
}
</script>

<style scoped>
  .modal .animation-content .modal-card {
    overflow: visible !important;
  }

  .modal-card-body {
    overflow: visible !important;
  }
</style>
