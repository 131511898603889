<template>
  <div>
    <div v-if="allContacts.length > 0">
      <b-table
        :data="allContacts"
        :bordered="true"
        :striped="true"
        :narrowed="false"
        :hoverable="false"
        :loading="false"
        :focusable="false"
        :mobile-cards="true"
      >
        <b-table-column field="id" label="Número" v-slot="props">
          {{ props.row.phone }}
        </b-table-column>

        <b-table-column field="name" label="Apelido" v-slot="props">
          {{ props.row.name ?? '' }}
        </b-table-column>

        <b-table-column field="color" label="Cor" v-slot="props">
          <b-field>
            <span class="color" :style="{backgroundColor: props.row.color}"></span>
            {{ props.row.color }}
          </b-field>
        </b-table-column>

        <b-table-column field="name" label="Atualizado em" v-slot="props">
          {{ props.row.updated_at ? formatDateTime(props.row.updated_at) : '' }}
        </b-table-column>

        <b-table-column field="name" label="Status da conexão" v-slot="props">
          <b-field>
            <b-tag v-if="props.row.api_type === W_API_TYPE.OFICIAL" type="is-success">Conectado</b-tag>
            <b-tag v-else-if="props.row.state === 'CONNECTED'" type="is-success">Conectado</b-tag>
            <b-tag v-else type="is-danger">Desconectado</b-tag>
          </b-field>
        </b-table-column>

        <b-table-column field="name" label="Ativo?" v-slot="props">
          <b-field>
            <IsActiveTag :active="props.row.enabled" />
          </b-field>
        </b-table-column>

        <b-table-column label="Atualizar" v-slot="props">
          <b-button type="is-warning is-light" outlined @click="updateNumber(props.row)">Atualizar</b-button>
        </b-table-column>

        <b-table-column field="update_delete" label="" v-slot="props">
          <b-tooltip label="Ler QR Code" type="is-dark" v-if="props.row.api_type === W_API_TYPE.WWEBJS && props.row.enabled">
            <b-button
              @click="readQr(props.row.phone)"
              type="is-warning"
              icon-pack="fas"
              icon-left="qrcode"
            />
          </b-tooltip>
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
import { API_TYPE } from '../../utils/constants'
import mixin from '../../utils/mixins'
import IsActiveTag from '../commons/IsActiveTag.vue'

export default {
  name: 'TokenList',
  props: {
    tokens: {
      type: Array,
      required: true
    }
  },
  components: {
    IsActiveTag,
  },
  created () {
    this.W_API_TYPE = API_TYPE
  },
  data () {
    return {
      W_API_TYPE: {},
    }
  },
  mixins: [mixin],
  computed: {
    allContacts: {
      get () {
        return this.tokens
      },
      set () {
        // do nothing
      }
    }
  },
  methods: {
    readQr (phone) {
      this.$buefy.dialog.confirm({
        title: 'Ler QRCode',
        message: 'Esta ação causará a desconexão do número selecionado. Deseja continuar?',
        confirmText: 'Sim',
        cancelText: 'Não',
        type: 'is-danger',
        hasIcon: true,
        icon: 'exclamation-triangle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true,
        onConfirm: () => {
          this.$emit('readQr', phone)
        }
      })
    },
    updateNumber (phone) {
      this.$emit('updateNumber', phone)
    }
  }
}
</script>

<style lang="scss" scoped>

.color {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: inline-block
}

</style>
