<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar :has-right-visible="false">
      QRCode
    </hero-bar>
    <section class="section is-main-section">
      <ModalQrCode v-if="isComponentModalActive" v-on:closeModal="closeModal"
        :isComponentModalActive="isComponentModalActive" :qrCode="getQRCode" />
      <!-- <button @click="resetTokensHandler" class="button is-success mb-2">Reiniciar tokens</button> -->
      <b-loading v-if="fetchingQrCode" :is-full-page="true" v-model="fetchingQrCode" :can-cancel="false"></b-loading>
      <TokenList v-else :tokens='allTokens' v-on:readQr="handleReadQr" v-on:updateNumber="updateNumber"/>
      <!-- <FabButton v-on:addClick="handleAddClick" iconClass="fas fa-pen-fancy" title="Mapear QRCode" /> -->
      <FabButton v-on:addClick="handleAddClick" iconClass="fas fa-pen-fancy" title="Novo número" />
      <ModalInsertNumber v-if="isCreatePhoneModalActive" :isCreatePhoneModalActive="isCreatePhoneModalActive" :numberUpdating="numberUpdating"
      v-on:closeNumberModal="closeNumberModal" v-on:addNewNumber="handleAddNumber" v-on:updateNumber="handleUpdateNumber"/>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import { mapActions, mapGetters } from 'vuex'
import TokenList from '@/components/settings/TokenList.vue'
import ModalQrCode from '@/components/modals/ModalQrCode'
import { io } from 'socket.io-client'
import ModalInsertNumber from '../components/modals/ModalInsertNumber.vue'
import FabButton from '../components/FabButton.vue'

export default {
  name: 'QRCode',
  components: {
    HeroBar,
    TitleBar,
    ModalQrCode,
    TokenList,
    ModalInsertNumber,
    FabButton
  },
  data () {
    return {
      isComponentModalActive: false,
      phoneReadingQr: '',
      isCreatePhoneModalActive: false,
      numberUpdating: null
    }
  },
  computed: {
    ...mapGetters(['allTokens', 'fetchingQrCode', 'getQRCode', 'getToken']),
    titleStack () {
      return ['Configurações', 'QRCode']
    }
  },
  methods: {
    ...mapActions(['configSettings', 'fetchTokens', 'fetchQRCode', 'changeQRCodeLocal', 'resetTokens', 'fetchWarnings', 'addPhone', 'updatePhone']),
    handleReadQr (phone) {
      this.phoneReadingQr = phone
      this.getImageUriAndOpenModal(phone)
    },
    handleAddClick () {
      this.isCreatePhoneModalActive = true
    },
    closeNumberModal () {
      this.numberUpdating = null
      this.isCreatePhoneModalActive = false
    },
    handleAddNumber (phone) {
      this.closeNumberModal()
      try {
        this.addPhone(phone).then(() => {
          this.$buefy.toast.open({
            message: 'Número atualizado com sucesso',
            type: 'is-success'
          })
          this.fetchWarnings()
        })
        
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message,
          type: 'is-danger'
        })
      }
    },
    handleUpdateNumber (phone) {
      this.closeNumberModal()
      try {
        this.updatePhone(phone).then(() => {
          this.$buefy.toast.open({
            message: 'Número atualizado com sucesso',
            type: 'is-success'
          })
          this.fetchWarnings()
        })
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message,
          type: 'is-danger'
        })
      }
    },
    updateNumber (phone) {
      this.numberUpdating = phone
      this.isCreatePhoneModalActive = true
    },
    closeModal () {
      this.isComponentModalActive = false
    },
    async getImageUriAndOpenModal (phone) {
      try {
        await this.fetchQRCode(phone)
      } catch {
        return 'assets/fallback.png'
      }
    },
    resetTokensHandler () {
      this.resetTokens()
      setTimeout(() => {
        this.fetchWarnings()
      }, 500)
    }
  },
  created () {
    this.fetchTokens()

    this.socket = io(process.env.VUE_APP_SOCKETIO_URL ?? process.env.VUE_APP_GTCHAT_URL, {
      reconnectionDelayMax: 10000,
      query: {
        token: this.getToken
      },
      path: `${process.env.VUE_APP_SOCKETIO_PATH ?? '/socket.io'}`
    })

    this.socket.on('connection', () => {
      console.warn('connected')
      this.fetchWarnings()
    })

    this.socket.on('qr_authenticated', () => {
      this.isComponentModalActive = false
      this.fetchTokens()
      this.fetchWarnings()
    })

    this.socket.on('qr_changed', (qr) => {
      if (this.phoneReadingQr === qr.phone) {
        this.changeQRCodeLocal(qr)
        this.isComponentModalActive = true
      }
    })
  }
}
</script>
