<template>
    <div>
    <section>
      <b-modal :active="isCreatePhoneModalActive" @close="closeModal">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p v-if="numberUpdating" class="modal-card-title">
              Atualizar Número
            </p>
            <p v-else class="modal-card-title">Inserir Número</p>
          </header>
          <section class="modal-card-body" style="overflow-y: auto !important">
            <b-field label="Tipo de conexão">
              <b-select
                placeholder=""
                v-model="api_type"
                expanded
              >
                <option value="OFICIAL">API Oficial</option>
                <option value="WWEBJS">API Local</option>
              </b-select>
            </b-field>
            <b-field label="Token de acesso" v-if="api_type === W_API_TYPE.OFICIAL">
              <b-input
                type="textarea"
                v-model="ww_clould_api_token"
                placeholder="Token de acesso"
              >
              </b-input>
            </b-field>
            <b-field grouped label="Identificação da conta do WhatsApp Business" v-if="api_type === W_API_TYPE.OFICIAL">
              <b-input
                type="text"
                v-model="waba_id"
                placeholder="Identificação da conta do WhatsApp Business"
                :disabled="numberUpdating"
                expanded
              >
              </b-input>
              <p class="control">
                <b-button type="is-info" label="Consultar" :loading="this.loading" @click="getPhones"/>
              </p>
            </b-field>
            <b-field label="Número" v-if="api_type === W_API_TYPE.OFICIAL">
              <b-select placeholder="Select a name" v-model="phone" expanded>
                <option
                    v-for="phone in data"
                    :value="phone.display_phone_number"
                    :key="phone.id">
                    {{ phone.display_phone_number}}
                </option>
              </b-select>
            </b-field>
            <b-field label="Identificação do número de telefone" v-if="api_type === W_API_TYPE.OFICIAL">
              <b-input
                type="text"
                v-model="phone_id"
                placeholder="Identificação do número de telefone"
                :disabled="numberUpdating"
              >
              </b-input>
            </b-field>
            <b-message
              title="Atenção"
              type="is-warning"
              v-if="api_type === 'WWEBJS'">
                • Para utilizar a API local, é necessário que o número de telefone esteja cadastrado no WhatsApp.
                <br><br>
                • Lembre-se de sempre verificar se o número possui o dígito 9 dentro do perfil cadastrado no WhatsApp.
                <br><br>
                • Lembre-se de adicionar o número sem espaços, barras ou qualquer caracter especial. O número deve ser inserido no formato
                DDI+DDD+Número (Ex: 5511999999999).
            </b-message>
            <b-field label="Número" v-if="api_type !== W_API_TYPE.OFICIAL">
              <b-input
                type="text"
                v-model="phone"
                placeholder="Número"
                :disabled="numberUpdating"
              >
              </b-input>
            </b-field>

            <b-field label="Key" v-if="api_type === 'OFICIAL'" grouped>
              <b-input
                type="text"
                v-model="ww_clould_api_key"
                placeholder="Key de autorização"
                expanded
              >
              </b-input>
              <p class="control">
                <b-button type="is-success" label="Gerar chave" @click="randomKey"/>
              </p>
              <p class="control">
                <b-button @click="copyKey" type="is-info">Copiar</b-button>
              </p>
            </b-field>
            <div v-if="api_type === 'OFICIAL'" class="has-text-centered mx-2">
                <a @click="copyWebhookLink">
                  Copiar URL de retorno de chamada do webhook
                </a>
            </div>
            <b-field label="Opções">
              <div class="field my-4">
                <b-checkbox v-model="enabled">Número ativo</b-checkbox>
              </div>
            </b-field>
            <b-field v-if="debugData">
              <template #label>
                <span>Permissões do token </span>
                <b-tooltip label="Recarregar" position="is-top">
                  <a class="is-info is-small" @click="getScope">
                    <b-icon
                      pack="fas"
                      icon="sync-alt"
                      id="loading-permission-item">
                    </b-icon>
                  </a>
                </b-tooltip>
              </template>
              <span class="control is-expanded"><b-icon icon="circle" custom-size="default" :type="getPermissionType('whatsapp_business_management')"/>Permissão para criar, editar, excluir templates</span>
              <br>
              <br>
              <span class="control is-expanded"><b-icon icon="circle" custom-size="default" :type="getPermissionType('whatsapp_business_messaging')"/>Permissão para enviar e receber mensagens</span>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="closeModal">
              Fechar
            </button>
            <button
              v-if="numberUpdating"
              class="button is-primary"
              @click="updateNumber"
            >
              Atualizar
            </button>
            <button v-else class="button is-primary" @click="addNumber">
              Gravar
            </button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import { API_TYPE } from '../../utils/constants'
export default {
  name: 'ModalInsertNumber',
  props: {
    isCreatePhoneModalActive: {
      type: Boolean,
      required: true
    },
    numberUpdating: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      phone: '',
      api_type: '',
      ww_clould_api_token: '',
      ww_clould_api_key: '',
      waba_id: '',
      phone_id: '',
      enabled: true,
      data: {},
      loading: false,
      W_API_TYPE: {},
      debugData: null
    }
  },
  created () {
    this.W_API_TYPE = API_TYPE
    if (this.numberUpdating) {
      this.phone = this.numberUpdating.phone
      this.api_type = this.numberUpdating.api_type
      this.ww_clould_api_key = this.numberUpdating.ww_clould_api_key
      this.ww_clould_api_token = this.numberUpdating.ww_clould_api_token
      this.waba_id = this.numberUpdating.waba_id
      this.phone_id = this.numberUpdating.phone_id
      this.enabled = this.numberUpdating.enabled
      if (this.api_type === API_TYPE.OFICIAL) {
        this.getPhones()
        this.getScope()
      }
    }
  },
  methods: {
    getPermissionType (permission) {
      if (this.debugData) {
        const scopes = this.debugData.scopes
        if (scopes.includes(permission)) {
          return 'is-success'
        } else {
          return 'is-danger'
        }
      } else {
        return 'is-danger'
      }
    },
    async addNumber () {
      if (this.phone === '') {
        this.$buefy.dialog.alert({
          title: 'Insira o Número de Telefone',
          message: 'Insira o número de telefone antes de prosseguir!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      } else if (this.api_type === API_TYPE.OFICIAL && this.ww_clould_api_token === '') {
        this.$buefy.dialog.alert({
          title: 'Insira o Token',
          message: 'Insira o token antes de prosseguir!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      } else if (this.api_type === API_TYPE.OFICIAL && this.ww_clould_api_key === '') {
        this.$buefy.dialog.alert({
          title: 'Insira a Key',
          message: 'Insira a chave de verificação antes de prosseguir!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      } else {
        this.$buefy.dialog.confirm({
          title: 'Incluir novo número',
          message: 'Incluir um novo número pode acarretar em cobranças adicionais de acordo com o contrato em vigência. Deseja continuar?',
          confirmText: 'Sim',
          cancelText: 'Não',
          type: 'is-danger',
          hasIcon: true,
          icon: 'exclamation-triangle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true,
          onConfirm: () => {
            this.$emit('addNewNumber', {
              phone: this.phone,
              api_type: this.api_type,
              ww_clould_api_token: this.ww_clould_api_token,
              ww_clould_api_key: this.ww_clould_api_key,
              waba_id: this.waba_id,
              phone_id: this.phone_id,
              enabled: this.enabled
            })
          }
        })
      }
    },
    async updateNumber () {
      if (this.api_type === API_TYPE.OFICIAL && this.ww_clould_api_token === '') {
        this.$buefy.dialog.alert({
          title: 'Insira o Token',
          message: 'Insira o token antes de prosseguir!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      } else if (this.api_type === API_TYPE.OFICIAL && this.ww_clould_api_key === '') {
        this.$buefy.dialog.alert({
          title: 'Insira a Key',
          message: 'Insira a chave de verificação antes de prosseguir!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      } else {
        this.$emit('updateNumber', {
          phone: this.phone,
          api_type: this.api_type,
          ww_clould_api_token: this.ww_clould_api_token,
          ww_clould_api_key: this.ww_clould_api_key,
          waba_id: this.waba_id,
          phone_id: this.phone_id,
          enabled: this.enabled
        })
      }
    },
    async getPhones () {
      if (this.waba_id && this.ww_clould_api_token) {
        this.loading = true
        const phones = await axios.get(`https://graph.facebook.com/v16.0/${this.waba_id}/phone_numbers`, {
          headers: {
            Authorization: `Bearer ${this.ww_clould_api_token}`
          }
        }).then((response) => response.data)
          .catch((error) => {
            this.$buefy.toast.open({
              message: `Erro ao buscar os números de telefone: ${error.response.data.error.message}`,
              type: 'is-danger',
              position: 'is-bottom-right'
            })
          })
        this.loading = false
        this.data = phones.data
      } else {
        this.$buefy.dialog.alert({
          title: 'Insira o Token',
          message: 'Insira o token antes de prosseguir!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },
    async getScope () {
      if (this.waba_id && this.ww_clould_api_token) {
        const element = document.getElementById('loading-permission-item')
        if (element) {
          element.classList.add('fa-spin')
        }
        const scope = await axios.get(`https://graph.facebook.com/v16.0/debug_token?input_token=${this.ww_clould_api_token}`, {
          headers: {
            Authorization: `Bearer ${this.ww_clould_api_token}`
          }
        }).then((response) => response.data.data ? response.data.data : null)
          .catch((error) => {
            this.$buefy.toast.open({
              message: `Erro ao buscar informações do token informado: ${error.response.data.error.message}`,
              type: 'is-danger',
              position: 'is-bottom-right'
            })
          })
        if (element) {
          element.classList.remove('fa-spin')
        }
        this.debugData = scope
      } else {
        this.$buefy.dialog.alert({
          title: 'Insira o Token',
          message: 'Insira o token antes de prosseguir!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },
    closeModal () {
      this.$emit('closeNumberModal')
    },
    randomKey () {
      const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      let codigo = ''
      for (let i = 0; i < 20; i++) {
        const indiceAleatorio = Math.floor(Math.random() * caracteres.length)
        codigo += caracteres.charAt(indiceAleatorio)
      }
      this.ww_clould_api_key = codigo
    },
    copyKey () {
      const input = document.createElement('input')
      input.setAttribute('value', this.ww_clould_api_key)
      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      document.body.removeChild(input)
      this.$buefy.toast.open({
        message: 'Chave copiada para a área de transferência',
        type: 'is-dark'
      })
    },
    copyWebhookLink () {
      const input = document.createElement('input')
      input.setAttribute('value', `${process.env.VUE_APP_GTCHAT_URL}/whatsapp/webhook`)
      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      document.body.removeChild(input)
      this.$buefy.toast.open({
        message: 'Link copiado para a área de transferência',
        type: 'is-dark'
      })
    }
  },
  watch: {
    phone (val) {
      if (this.api_type === API_TYPE.OFICIAL && this.data.length > 0) {
        this.phone_id = this.data.find((phone) => phone.display_phone_number === val).id
      }
    }
  }
}
</script>
